import React from "react"

const FormRequiredFields = ({ errors, formFields, state }) => {
  let requiredFields = []

  for (let i = 0; i < formFields?.length; i++) {
    let formFieldName = formFields[i]?.name

    if (errors[formFieldName]) {
      requiredFields.push({
        label: formFields[i]?.label,
        sectionOrder: formFields[i]?.sectionOrder,
        order: formFields[i]?.order,
      })
    } else if (
      formFields[i]?.type === "upload" &&
      state &&
      (state[formFieldName]?.length === 0 || state.documents?.length === 0)
    )
      requiredFields.push({
        label: formFields[i]?.label,
        sectionOrder: formFields[i]?.sectionOrder,
        order: formFields[i]?.order,
      })
  }

  requiredFields.sort((a, b) => {
    return a.sectionOrder - b.sectionOrder || a.order - b.order
  })

  return (
    <div className="mt-1">
      {requiredFields.map((requiredField) => {
        return <li>{requiredField.label}</li>
      })}
    </div>
  )
}

export default FormRequiredFields
