import getLabelValueByLabel from "../../../utils/getLabelValueByValue"
import { heightAndWeightOptions } from "../../../../Elements/Form/utils/heightWeightOptions"

export const assignInitialValues = ({ initialValues, states, location }) => {
  if (location.state?.email) initialValues["email"] = location?.state?.email

  states.forEach((state) => {
    if (state === null || state === undefined) {
      return
    }

    Object.keys(state).forEach((item) => {
      switch (typeof state[item]) {
        case "object":
          if (state[item] === null) break
          // date
          if ("year" in state[item]) {
            if (
              state[item].date?.value !== "" ||
              (state[item]?.date !== "" && !"value" in state[item])
            )
              state[item].date = {
                label: state[item].date?.label || state[item].date,
                value: state[item].date?.value || state[item].date,
              }
            if (
              state[item].year?.value !== "" ||
              (state[item]?.year !== "" && !"value" in state[item])
            )
              state[item].year = {
                label: state[item].year?.label || state[item].year,
                value: state[item].year?.value || state[item].year,
              }
            if (
              state[item].month?.value !== "" ||
              (state[item]?.month !== "" && !"value" in state[item])
            )
              state[item].month = {
                label: state[item].month?.label || state[item].month,
                value: state[item].month?.value || state[item].month,
              }

            initialValues[item] = state[item]
            // address
          } else if ("streetAddress" in state[item]) {
            if (state[item].addressType === "")
              state[item].addressType = initialValues[item].addressType

            if (
              state[item].province?.value !== "" ||
              (state[item]?.province !== "" && !"value" in state[item])
            )
              state[item].province = {
                label: state[item].province?.label || state[item].province,
                value: state[item].province?.value || state[item].province,
              }

            if (
              state[item].city?.value !== "" ||
              (state[item]?.city !== "" && !"value" in state[item])
            )
              state[item].city = {
                label: state[item].city?.label || state[item].city,
                value: state[item].city?.value || state[item].city,
              }
            if (
              state[item].barangay?.value !== "" ||
              (state[item]?.barangay !== "" && !"value" in state[item])
            ) {
              state[item].barangay = {
                label: state[item].barangay?.label || state[item].barangay,
                value: state[item].barangay?.value || state[item].barangay,
              }
            }
            initialValues[item] = state[item]
            // array
          } else if (state[item]?.length > 0) {
            initialValues[item] = state[item]
          } else {
            // default select
            if (state[item]?.value) {
              initialValues[item] = {
                label: state[item]?.value || state[item],
                value: state[item]?.value || state[item],
              }
            }
          }
          break
        case "string":
          if (state[item].trim() !== "") {
            if (state[item].includes("+63"))
              initialValues[item] = state[item].slice(3)
            else initialValues[item] = state[item]
          }
          if (["hospitalOrClinic"].includes(item)) {
            initialValues[item] = {
              label: state[item],
              value: state[item],
            }
          }

          if (["height", "weight"].includes(item)) {
            initialValues[item] = getLabelValueByLabel(
              state[item],
              heightAndWeightOptions[item]
            )
          }

          break
      }
    })
  })

  return initialValues
}
