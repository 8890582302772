import React, { useContext, useState, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import classNames from "classnames"

import Layout from "../../../Layout"
import Container from "../../../Layout/Container"
import Section from "../../../Elements/Section"
import Message from "../../../Elements/Message"
import ActionButtons from "../../../Elements/ActionButtons"
import FormRequiredFields from "../../../Elements/Form/FormRequiredFields"
import { generateFormField } from "../../../Elements/Form/services/form"
import { AuthContext } from "context/AuthContext"

import { firebaseApi } from "services/firebaseApi"
import { generateJWT } from "services/jwt"
import { isBrowser } from "services/general"

import { formSchema } from "../../utils/formSchema"
import { PatientEnrollmentContext } from "../../EnrollmentContext/PatientEnrollmentContext"
import { generateInitialValues } from "../../utils/generateInitialValues"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import addSupTags from "../../utils/addSupTags"
import { getSignedInUser, hasSignedInUser } from "../../../Auth/services/user"
import { assignInitialValues } from "./services/assignInitialValues"
import { buildSessionDocument } from "../../../Elements/utils/buildSessionDocument"
import trimObjectWhitespaces from "../../utils/trimObjectWhitespaces"

let { parseFormField } = require("../../../../services/airtable")

const PatientEnrollmentForm = (props) => {
  const { pageContext, location } = props
  let { userData, addresses } = getSignedInUser()

  const [loading, setLoading] = useState(false)
  const { module, pageContent } = pageContext
  const { patientEnrollmentState, patientEnrollmentDispatch } = useContext(
    PatientEnrollmentContext
  )
  let formFields = pageContext.formFields

  let validationSchema = formSchema({ fields: formFields })

  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  useEffect(() => {
    patientEnrollmentDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [patientEnrollmentDispatch])

  const handleSuccessCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleErrorCallback = () => {
    setLoading(false)
  }

  const handleSubmit = async (values, actions) => {
    values = trimObjectWhitespaces(values)
    setLoading(true)
    let documents = patientEnrollmentState?.documents

    await patientEnrollmentDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: { ...patientEnrollmentState, ...values, documents },
    })

    await patientEnrollmentDispatch({
      type: "SAVE_PATIENT_ENROLLMENT",
      payload: values,
    })
    await patientEnrollmentDispatch({
      type: "SAVE_DOCUMENTS",
      payload: documents,
    })

    if (module?.title === "Health Profile Form" && documents.length === 0) {
      setLoading(false)
      actions.setFieldError("uploadPrescription", "This field is required")
      navigate("#uploadPrescription0")
    } else {
      if (!hasSignedInUser()) {
        const JWT_OBJECT = await generateJWT()
        const ACCESS_TOKEN = JWT_OBJECT?.data?.access_token

        let request = await firebaseApi({
          accessToken: ACCESS_TOKEN,
        }).post(`sessions`, {
          fields: buildSessionDocument({ values, location }),
        })
      }

      navigate(pageContext.nextPath)
    }
  }

  return (
    <Layout
      {...module}
      pageContext={pageContext}
      currentStep={1}
      totalSteps={2}
      display={{ footer: false }}
    >
      <Container isCentered>
        <Formik
          initialValues={assignInitialValues({
            initialValues: generateInitialValues({ fields: formFields }),
            states: [userData, patientEnrollmentState],
            location,
          })}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  id={section?.sectionId}
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="mt-1 mb-3"
                  isSectionRequired={section?.isSectionRequired}
                  helper={section?.helper}
                >
                  {section?.message && (
                    <p
                      className="has-text-primary mb-2"
                      dangerouslySetInnerHTML={{
                        __html: addSupTags(section?.message),
                      }}
                    />
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: {
                              ...field,
                              disabled:
                                field?.name === "email" &&
                                !!location.state?.fromAuth,
                            },
                            values,
                            setFieldValue,
                            state: patientEnrollmentState,
                            dispatch: patientEnrollmentDispatch,
                            type: "patient-enrollment",
                            errors,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }

                    return null
                  })}
                </Section>
              ))}

              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete for the
                  following fields:
                  <FormRequiredFields
                    errors={errors}
                    formFields={formFields}
                    state={patientEnrollmentState}
                  />
                </Message>
              )}

              <ActionButtons
                back={{
                  label: <FontAwesomeIcon icon={faChevronLeft} />,
                  link: pageContext.backPath,
                }}
                submit={{
                  label:
                    "Next: " +
                    (module?.title === "Health Profile Form"
                      ? "Summary"
                      : "Health Profile Form"),
                  loading: loading,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default PatientEnrollmentForm
